import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba } from 'polished'

import { StoreCtx } from '../store'
import Seo from '../components/seo'
import { Container, Row, Column } from '../components/ui/layout/Grid'
import Chart from '../components/Chart'

const Legal = styled.div`
  border: 1px solid ${ props => props.theme.color.grey[1] };
  padding: 2em;

  h5 {
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
  }
`

const Breadcrumbs = styled.div`
  font-weight: 700;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
  }

  a {
    background-color: ${ props => rgba(props.theme.color.primary[0], 0.5) };
    color: ${ props => props.theme.color.primary[5] };
    padding: 0.3em 0.7em;
    border-radius: 20px;
    opacity: 0.8;

    :hover {
      opacity: 1;
    }
  }

  span {
    color: ${ props => props.theme.color.grey[4] };

    &::before {
      content: ">";
      margin: 0 0.3em;
    }
  }
`

const Asset = ({ pageContext }) => {
  const { coin } = pageContext
  return (
    <>
      <Container>
        <Seo title={coin.name + ' Price'} />
        <Row dir="column">
          <h1
            css={`
              margin-bottom: 0;
            `}
          >
            {coin.name}
          </h1>
          <Breadcrumbs>
            <ul>
              <li>
                <Link to="/price">Price</Link>
              </li>
              <li>
                <span>{coin.name}</span>
              </li>
            </ul>
          </Breadcrumbs>
        </Row>
        <Row>
          <Column>
            <StoreCtx.Consumer>
              {({ data }) => (
                <Chart
                  data={coin.performance}
                  range={data.range}
                />
              )}
            </StoreCtx.Consumer>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column>
            <Legal>
              <h5>Legal Disclaimer</h5>
              <p>
                Investabit’s Indexes, Price Data and this website are provided on an "as is" basis and Investabit Capital Inc makes
                no representations or warranties as to the quality, accuracy or suitability of Investabit Index or this
                website. You acknowledge and agree that Investabit Captial, Inc. is not acting as an investment advisor
                or portfolio management by providing access to the Investabit Indexes and shall bear no responsibility
                or liability for any loss or damage, howsoever caused, including due to amendment of this website or the
                index methodology or calculations, resulting from or in connection with your use of Investabit Indexes
                or this website for any purpose.
              </p>
            </Legal>
          </Column>
        </Row>
      </Container>
    </>
  )
}

export default Asset
